<template>
	<div>
		<template>	
			<div class="upload-main">
				
				<label :class="{'upload-div-img':!rect,'upload-div-img rect':rect}" v-for="(item, index) in files" v-show="item" :key="index">
					<img :src="item.url">
					<div class="upload-bg-img">
						<span class="iconfont icon-zoom-out" @click="preview(index)"></span>
						<span class="iconfont icon-ashbin" @click="remove(index)"></span>
					</div>
				</label>
				
				<label :class="{'upload-div-add-img':!rect,'upload-div-add-img rect':rect}" :for="id" v-show="!(limit !== 0 && limit == files.length)">
					<span class="iconfont icon-add"></span>
				</label>
				
			</div>
		</template>
		
		<input ref="vueUploadImg" :disabled="disabled" type="file" :id="id" :accept="access" hidden @change="fileChangeHandler" :multiple="multiple">
	</div>
</template>
 
<script>
module.exports = {
	model: {
		prop: 'files',
		event: 'change',
	},
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		//矩形
		rect: {
			type: Boolean,
			default: false,
		},
		type: {
			type: Number,
			default: 0, // 0 预览模式 1 列表模式 2 预览模式 + 上传按钮
		},
		access: {
			type: String,
			default: 'image/*',
		},
		files: {
			type: Array,
			default: () => [], // 图片
			required: true,
		},
		label: {
			type: String,
			default: '点击上传', // 按钮文字
		},
		limit: {
			type: Number,
			default: 1, // 限制上传的图片数， 0 为不限制
		},
		maxSize: {
			type: Number,
			default: null, // 图片最大尺寸，超过将不添加并触发 oversize 事件
		},
		multiple: {
			type: Boolean,
			default: false, // 是否多选
		},
		compress: {
			type: Boolean,
			default: false, // 是否开启压缩
		},
		quality: {
			type: Number,
			default: 0.8, // 默认压缩质量
		},
		beforeRead: { // 读取前钩子函数
			type: Function,
			default: null,
		},
		afterRead: { // 读取后钩子函数
			type: Function,
			default: null,
		},
		beforeRemove: { // 移除前钩子函数
			type: Function,
			default: null,
		},
	},
	
    data: function() {
        return {
            id: 'file' + Math.random().toString(16).slice(-13).replace(/\./g, ''),
        }
    },
	
	methods: {
		remove(index) {
			if (this.disabled) return
			if (this.beforeRemove && !this.beforeRemove(index, this.files[index])) return
			const files = this.files.slice()
			files.splice(index, 1)
			this.$emit('change', files)
		},
		preview(index) {
			console.log('--- preview ---');
			this.$emit('preview', index, this.files[index])
		},
		fileChangeHandler(e) {
			const newFiles = e.target.files
			if (this.beforeRead && !this.beforeRead(newFiles)) {
				this.$refs.vueUploadImg.value = ''
				return
			}
			if (!this.verify(this.files.length + newFiles.length)) return 
			this.readFiles(newFiles)
		},
		readFiles(newFiles) {
			let canvas, ctx
			if (this.compress) {
				canvas = document.createElement('canvas')
				ctx = canvas.getContext('2d')
			}
			const oldFiles = [...this.files]
			const result = []
			for (let i = 0, len = newFiles.length; i < len; i++) {
				const file = newFiles[i]
				if (file.type.includes('image')) {
					const reader = new FileReader()
					reader.onload = (res) => {
						const fileResult = res.target.result 
						if (!this.compress) {
							this.fileLoaded(file, fileResult, result, len, oldFiles)
						} else {
							const img = new Image()
							img.onload = () => {
								const w = img.width
								const h = img.height
								// let w = 148;
								// let h = 148
								// if(img.width>img.height){
								// 	w = img.height
								// 	h = img.height
								// }
								// else{
								// 	w = img.width
								// 	h = img.width
								// }
								canvas.setAttribute('width', w)
								canvas.setAttribute('height', h)
								ctx.drawImage(img, 0, 0, w, h)
								const base64 = canvas.toDataURL(file.type, this.quality)
								this.fileLoaded(file, base64, result, len, oldFiles)
							}
							img.src = fileResult
						}
					}
					reader.readAsDataURL(file)
				}
			}
		},
		fileLoaded(file, content, result, len, oldFiles) {
			if (this.maxSize !== null && this.isOverSize(file)) {
				this.$emit('oversize', file)
				return
			}
			result.push({
				name: file.name,
				url: content,
				type: file.type,
				size: file.size,
			})
			if (len == result.length) {
				this.$emit('change', oldFiles.concat(result))
				this.afterRead && this.afterRead(result)
				this.$refs.vueUploadImg.value = ''
			}
		},
		isOverSize(file) {
			return file.size > this.maxSize
		},
		verify(len) {
			if (/^\d+$/.test(this.limit) && this.limit !== 0) {
				if (this.limit < len) {
					this.$emit('exceed')
					return false
				}
			}
			return true
		}
	},
	
}
</script>

<style>
@import '../assets/css/iconfont.css';


.upload-main {
    font-size: 0;
}

.upload-div-add-img,
.upload-div-img {
    min-width: 451px;
    width: 451px;
    height: 100px;
    /* border-radius: 6px; */
    border: 1px solid #c0ccda;
    overflow: hidden;
    position: relative;
    margin-right: 6px;
    margin-top: 5px;
    transition: opacity .3s;
    display: inline-block;
    font-size: 14px;
}

.upload-div-img img {
    width: 100%;
    height: 100%;
}

.upload-div-add-img {
    background-color: #fbfdff;
    border-style: dashed;
    cursor: pointer;
}
.upload-div-add-img .iconfont {
    font-size: 28px;
    color: #8c939d;
    font-weight: normal;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}


.upload-div-img .iconfont {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%,-50%);
    color: #fff;
    z-index: 1;
    cursor: pointer;
    font-size: 20px;
}

.upload-div-img .icon-add {
    left: 40%;
}
.upload-div-img .icon-ashbin {
    left: 60%;
}
.upload-bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    display: none;
}
.upload-div-img:hover {
    opacity: .8;
}
.upload-div-img:hover .upload-bg-img {
    display: block;
}


.upload-bg-img .icon-ashbin {
    margin-left: 10px;
    cursor: pointer;
    color: #fff;
}

.rect {
	width: 240px !important;
	height: 120px !important;
	}

	
</style>