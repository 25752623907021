<template>
	<div class="container-fluid animatedParent animateOnce">

		<div class="row">
			<div class="col-md-9">
				<div class="card mt-3 shadow">
					<div class="card-header white">
						<h6>商品资料</h6>
					</div>
					<div class="card-body">
						<div class="needs-validation" novalidate="">

							<div class="form-row">
								<div class="col-md-6 mb-3">
									<label for="">分类</label>
									<select class="form-control" v-model="product.category_id">
										<option v-bind:key="index" :value="item.id"
											v-for="(item,index) in categorylist">{{item.category_name}}</option>
									</select>
								</div>
								<div class="col-md-6 mb-3">
									<label for="">品名</label>
									<input v-model="product.product_name" type="text" class="form-control"
										placeholder="品名,不超过8个字">
									<div class="valid-feedback">
										Looks good!
									</div>
								</div>
							</div>
							<div class="form-row">
								<div class="col-md-6 mb-3">
									<label for="">价格(元)</label>
									<input v-model="product.price" type="number" class="form-control" placeholder="单价">
								</div>
								<div class="col-md-6 mb-3">
									<label for="">规格单位</label>
									<input v-model="product.unit" type="text" class="form-control"
										placeholder="规格单位,如：份、杯、盅等">
									<small class="form-text text-muted">
									</small>
								</div>
							</div>




							<!-- <button v-if="product.id>0" @click="remove" type="button" class="btn btn-dark"> 删除 </button> -->
							<button @click="save" :disabled="disabled" type="button" class="btn btn-primary"> 保存
							</button>
						</div>


					</div>
				</div>
			</div>
			<div class="col-md-3">
				<div class="card mt-3 shadow">
					<div class="card-header white">
						<h6>商品图片</h6>
					</div>
					<div class="card-body text-center">
						<VueUploadImgs compress :before-read="beforeRead" :after-read="afterRead"
							:before-remove="beforeRemove" :limit="limit" :type="type" @preview="preview"
							@exceed="exceed" @oversize="oversize" v-model="files">
						</VueUploadImgs>
						<div class="s-12 mt-2">图片格式为jpg,jpeg,gif,png，正方形，推荐尺寸为480x480像素</div>
					</div>
				</div>


			</div>
		</div>

		<!-- //main content -->

		<!-- modal -->
		<!-- 图片预览 -->
		<div class="modal fade" id="previewModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">商品图片预览</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body center">

						<div class="m-3">

							<img :src="previewIMG" class="preview-img">

						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal"> 关闭 </button>
					</div>
				</div>
			</div>
		</div>

		<!-- //modal -->
	</div>
</template>

<script>
	import VueUploadImgs from '../components/VueUploadImgs.vue'
	
	export default  {
		data() {
			return {
				title: '创建商品',

				token: '',
				outlet_id: 0,
				outlet_name: '',
				user_name: '',

				product: {
					product_name: '',
					price: '',
					category_id: 0,
					unit: '',
				},

				disabled: false,

				categorylist: [],
				category: {}, //选中的会员级别

				setting: {},

				//图片上传
				files: [],
				maxSize: 1024 * 10, // 10 KB
				previewIMG: null,
				limit: 1,
				isPreview: false,
				type: 2, // 0 预览模式 1 列表模式 2 预览模式 + 上传按钮
			}
		},

		components: {
			VueUploadImgs
		},

		created: function() {
			console.log('--- created --- ')
			let self = this;

			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;

			//2.取得店铺信息
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			console.log(lsLoginData.logined_outlet_name)
			// self.outlet_id = lsLoginData.logined_outlet_id;
			console.info('--- this.$store.state ->', this.$store.state)
			self.outlet_id = this.$store.state.current_outlet_id || lsLoginData.logined_outlet_id;
			self.outlet_name = lsLoginData.logined_outlet_name;
			self.user_name = lsLoginData.user_account;

			self.setting = JSON.parse(localStorage.getItem('SETTING'));

			self.outletlist = JSON.parse(localStorage.getItem('OUTLETLIST'));
			console.log('--- outlet list ->', self.outletlist)

			//指定店铺
			// let selectedOutletId = getQueryString("outlet_id");
			console.log('--- this.$route>', this.$route)
			let selectedOutletId = this.$route.params.outlet_id;
			if (selectedOutletId) {
				self.outlet_id = selectedOutletId;
			}

		},
		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;

			//1.检查登录
			// let token = localStorage.getItem('TOKEN');
			// if (!self.token) {
			// 	console.log('--- mounted jump  --- ')
			// 	location.href = 'index.html';
			// 	return;
			// }

			self.initData();
		},
		methods: {
			initData() {
				let self = this;

				//取得员工列表
				this.axios.get(this.GLOBAL.baseURI + 'getCyCategoryList', {
						params: {
							token: self.token,
							outlet_id: self.outlet_id,
							// name: self.query,
							// current_page: self.current_page,
							// page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
							self.categorylist = data.data;

							if (self.categorylist.length > 0) {
								self.product.category_id = self.categorylist[0].id || 0; //默认第一个
							} else {
								alert('该店铺没有商品分类');
							}

							// __LIST = data.data;
						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							alert(data.message)
						}
					});
				//END

			},


			save() {
				let self = this;
				console.log(self.product);
				// return;

				//校验
				if (self.product.category_id == 0) {
					alert('请选择商品分类')
					return;
				}

				if (!self.product.product_name) {
					alert('品名不能为空')
					return;
				}

				if (!self.product.price) {
					alert('价格不能为空')
					return;
				}

				if (!self.product.unit) {
					alert('规格不能为空')
					return;
				}


				self.disabled = true;

				// let tags = $(".tags-input").tagsinput('items');
				// let tag = tags.length>0?tags.join():'';

				// 组装数据
				// let postData = {
				// 	token: self.token,
				// 	outlet_id: self.outlet_id,

				// 	product_name: self.product.product_name,
				// 	price: self.product.price || 0,
				// 	unit: self.product.unit,
				// 	category_id: self.product.category_id,

				// 	kitchen_print: 0,
				// 	scale_flag: 0,
				// }

				// 组装数据
				let postData = self.product;

				postData['token'] = self.token;
				postData["outlet_id"] = self.outlet_id;

				postData['kitchen_print'] = 0;
				postData['scale_flag'] = 0;

				let postData2 = new FormData();
				for (var key in postData) {
					console.log(key + ':' + postData[key]);
					postData2.append(key, postData[key]);
				}


				//调用简易录菜接口
				this.axios.post(this.GLOBAL.baseURI + 'addCyProduct',
						postData2
					)
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);

						self.disabled = false;

						if (data.code == 200) {
							alert('保存成功')
							self.$router.push({
								path: 'products',
								params: {
									outlet_id: self.outlet_id
								}
							})
						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							alert(data.message)
						}
					});
				//END
			},

			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;

				self.initData();
			},

			//图片上传
			oversize(file) {
				console.log('oversize')
				console.log('filesize:' + file.size / 1024 + 'KB')
			},

			afterRead(file) {
				console.log('after-read')
				console.log(file)


			},

			beforeRemove(index, file) {
				console.log(index, file)
				// delete this.product["img_file"];
				this.product["img_file"] = null;
				this.product["img_url"] = 'remove';

				return true
			},

			preview(index, file) {
				let url = file
					.url; //<img class="preview-img" src="https://oss-pk-test.oss-cn-shenzhen.aliyuncs.com/51118960.jpg?x-oss-process=style/sm-150x150&amp;0.836985165951373">
				let newurl = url.indexOf('aliyuncs.com') != -1 ? url.replace('sm-150x150', 'w480') : url;

				this.previewIMG = newurl;
				this.isPreview = true


				$('#previewModal').modal('show');
			},

			exceed() {
				alert(`只能上传${this.limit}张图片`)
			},

			beforeRead(files) {
				console.log('before-read')
				for (let i = 0, len = files.length; i < len; i++) {
					const file = files[i]
					if (file.type != 'image/jpeg' && file.type != 'image/png') {
						alert('只能上传jpg和png格式的图片')
						return false
					}
				}

				console.log('--- files  -> ', files[0])
				this.product["img_file"] = files[0];
				this.product["img_url"] = "HAVE";


				return true
			},
		},
	}
</script>

<style scoped>

</style>
